<template>
  <div>
    <b-tabs>
      <b-tab
        :title="$t('dollar-plans')"
        active
      >
        <div
          v-for="(plan, index) in dollarPlans"
          :key="index + 'dollarPlans'"
        >
          <b-card class="mb-3">
            <p>
              <span>{{ $t('plan').toUpperCase() }}</span> <span>|</span> <span>{{ $t('min-balance').toUpperCase() }}</span>: <strong>{{ numberWithCommas(plan.min_balance) }} {{ plan.currency.symbol }}</strong> -
              {{ $t('max-balance').toUpperCase() }}: <strong>{{ numberWithCommas(plan.max_balance) }} {{ plan.currency.symbol }}</strong> -
              {{ $t('currency') }}: <strong>{{ plan.currency.name }} ({{ plan.currency.symbol }})</strong>
            </p>
            <b-table
              striped
              hover
              :items="plan.durations"
            >
              <template #cell(name)="data">
                {{ data.item.duration >= 12 ? data.item.duration / 12 + ' '+$t('year') : data.item.duration + ' '+$t('month') }}
              </template>
              <template #cell(duration)="data">
                {{ data.item.duration >= 12 ? data.item.duration / 12 + ' '+$t('year') : data.item.duration + ' '+$t('month') }}
              </template>
              <template #cell(annual_interest)="data">
                {{ data.item.annual_interest + '%' }}
              </template>
              <template #cell(payment)="data">
                {{ $t('every')+' ' + (data.item.payment >= 12 ? data.item.payment / 12 + ' '+$t('year') : data.item.payment + ' '+$t('month')) }}
              </template>
            </b-table>
          </b-card>
        </div>
      </b-tab>

      <b-tab :title="$t('rial-plans')">
        <div
          v-for="(plan, index) in rialPlans"
          :key="index + 'rialPlans'"
        >
          <b-card class="mb-3">
            <p>
              <span>{{ $t('plan').toUpperCase() }}</span> <span>|</span> <span>{{ $t('min-balance').toUpperCase() }}</span>: <strong>{{ numberWithCommas(plan.min_balance) }} {{ $t('million') }} {{ plan.currency.symbol }}</strong> -
              {{ $t('max-balance').toUpperCase() }}: <strong>{{ numberWithCommas(plan.max_balance) }} {{ $t('million') }} {{ plan.currency.symbol }}</strong> -
              {{ $t('currency') }}: <strong>{{ plan.currency.name }} ({{ plan.currency.symbol }})</strong>
            </p>
            <b-table
              striped
              hover
              :items="plan.durations"
            >
              <template #cell(name)="data">
                {{ data.item.duration >= 12 ? data.item.duration / 12 + ' '+$t('year') : data.item.duration + ' '+$t('month') }}
              </template>
              <template #cell(duration)="data">
                {{ data.item.duration >= 12 ? data.item.duration / 12 + ' '+$t('year') : data.item.duration + ' '+$t('month') }}
              </template>
              <template #cell(annual_interest)="data">
                {{ data.item.annual_interest + '%' }}
              </template>
              <template #cell(payment)="data">
                {{ $t('every')+' ' + (data.item.payment >= 12 ? data.item.payment / 12 + ' '+$t('year') : data.item.payment + ' '+$t('month')) }}
              </template>
            </b-table>
          </b-card>
        </div>
      </b-tab>
    </b-tabs>

    <b-button
      :to="{ name: 'investModule-select-plan-type' }"
      class="bouncing-button"
      style="position: fixed; bottom: 50px; width: 150px;"
      :style="!$store.state.appConfig.layout.isRTL ? 'right: 50px;' : 'left: 50px;'"
      variant="success"
    >
      {{ $t('invest') }}
    </b-button>
  </div>
</template>

<script>
import { numberWithCommas } from '@/utils'
import { dollarPlans, rialPlans } from '../consts.ts'

export default {
  data() {
    return {
      numberWithCommas,
      dollarPlans,
      rialPlans,
      investTips: [
        { id: 1, value: 'Open a fixed deposit account with no fees' },
        { id: 2, value: 'No blocking and lock-in period - withdraw at any time' },
        { id: 3, value: 'Full withdrawal prior to maturity allowed' },
        { id: 4, value: 'Partial withdrawal prior to maturity allowed' },
        { id: 5, value: 'Flexible investment options available' },
      ],
    }
  },
}
</script>

<style scoped>
.bouncing-button {
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.2);
  }
}
</style>
