// eslint-disable-next-line import/prefer-default-export
export const dollarPlans = [
  {
    min_balance: 150,
    max_balance: 1500,
    currency: { name: 'US dollar', nic_name: 'USD', symbol: '$' },
    durations: [
      {
        name: '6 Month', duration: 6, annual_interest: 10, payment: 6,
      },
      {
        name: '1 Year', duration: 12, annual_interest: 12, payment: 12,
      },
      {
        name: '2 Years', duration: 24, annual_interest: 13, payment: 12,
      },
      {
        name: '3 Years', duration: 36, annual_interest: 14, payment: 12,
      },
    ],
  },
  {
    min_balance: 1501,
    max_balance: 5000,
    currency: { name: 'US dollar', nic_name: 'USD', symbol: '$' },
    durations: [
      {
        name: '6 Month', duration: 6, annual_interest: 11, payment: 6,
      },
      {
        name: '1 Year', duration: 12, annual_interest: 13, payment: 6,
      },
      {
        name: '2 Years', duration: 24, annual_interest: 14, payment: 12,
      },
      {
        name: '3 Years', duration: 36, annual_interest: 15, payment: 12,
      },
    ],
  },
  {
    min_balance: 5001,
    max_balance: 15000,
    currency: { name: 'US dollar', nic_name: 'USD', symbol: '$' },
    durations: [
      {
        name: '6 Month', duration: 6, annual_interest: 12, payment: 6,
      },
      {
        name: '1 Year', duration: 12, annual_interest: 14, payment: 6,
      },
      {
        name: '2 Years', duration: 24, annual_interest: 15, payment: 3,
      },
      {
        name: '3 Years', duration: 36, annual_interest: 16, payment: 3,
      },
    ],
  },
  {
    min_balance: 15001,
    max_balance: 100000,
    currency: { name: 'US dollar', nic_name: 'USD', symbol: '$' },
    durations: [
      {
        name: '6 Month', duration: 6, annual_interest: 13, payment: 3,
      },
      {
        name: '1 Year', duration: 12, annual_interest: 15, payment: 3,
      },
      {
        name: '2 Years', duration: 24, annual_interest: 16, payment: 1,
      },
      {
        name: '3 Years', duration: 36, annual_interest: 17, payment: 1,
      },
    ],
  },
]

export const rialPlans = [
  {
    min_balance: 10,
    max_balance: 100,
    currency: {
      name: 'Rial',
      nic_name: 'Rial',
      symbol: 'IRR',
    },
    durations: [
      {
        name: '6 Month',
        duration: 6,
        annual_interest: 35,
        payment: 6,
      },
      {
        name: '1 Year',
        duration: 12,
        annual_interest: 37,
        payment: 12,
      },
      {
        name: '2 Years',
        duration: 24,
        annual_interest: 39,
        payment: 12,
      },
      {
        name: '3 Years',
        duration: 36,
        annual_interest: 41,
        payment: 12,
      },
    ],
  },
  {
    min_balance: 100,
    max_balance: 500,
    currency: {
      name: 'Rial',
      nic_name: 'Rial',
      symbol: 'IRR',
    },
    durations: [
      {
        name: '6 Month',
        duration: 6,
        annual_interest: 36,
        payment: 6,
      },
      {
        name: '1 Year',
        duration: 12,
        annual_interest: 38,
        payment: 6,
      },
      {
        name: '2 Years',
        duration: 24,
        annual_interest: 40,
        payment: 12,
      },
      {
        name: '3 Years',
        duration: 36,
        annual_interest: 42,
        payment: 12,
      },
    ],
  },
  {
    min_balance: 500,
    max_balance: 1000,
    currency: {
      name: 'Rial',
      nic_name: 'Rial',
      symbol: 'IRR',
    },
    durations: [
      {
        name: '6 Month',
        duration: 6,
        annual_interest: 37,
        payment: 6,
      },
      {
        name: '1 Year',
        duration: 12,
        annual_interest: 39,
        payment: 6,
      },
      {
        name: '2 Years',
        duration: 24,
        annual_interest: 41,
        payment: 3,
      },
      {
        name: '3 Years',
        duration: 36,
        annual_interest: 43,
        payment: 3,
      },
    ],
  },
  {
    min_balance: 1000,
    max_balance: 5000,
    currency: {
      name: 'Rial',
      nic_name: 'Rial',
      symbol: 'IRR',
    },
    durations: [
      {
        name: '6 Month',
        duration: 6,
        annual_interest: 38,
        payment: 3,
      },
      {
        name: '1 Year',
        duration: 12,
        annual_interest: 40,
        payment: 3,
      },
      {
        name: '2 Years',
        duration: 24,
        annual_interest: 42,
        payment: 1,
      },
      {
        name: '3 Years',
        duration: 36,
        annual_interest: 45,
        payment: 1,
      },
    ],
  },
]
